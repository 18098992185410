import request from '@/utils/request'
import constant from '@/constant'
import { getToken } from '@/utils/auth' // get token from cookie

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/employeeInfo/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/employeeInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/employeeInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/employeeInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/employeeInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/employeeInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/employeeInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function downloadZip(idList, localPath) {
  window.open(constant.serverUrl + "/base/employeeInfo/downloadZip?ids=" + idList + 
  "&localPath=" + encodeURIComponent(localPath) + "&token=" + getToken());
}

export default {
  create, edit, add, update, remove, batchRemove, pageList, downloadZip
}